@import 'src/base';

.side-panel {
    background-color: $white;
    display: none;
    .ant-layout-sider-trigger{
        background: $primary-color;
    }
}

.admin-sites-btn {
    width: 100%;
    margin: 0;
    text-transform: uppercase;
    padding: 0;
    font-size: 8pt;
    font-weight: 600;
}

@media screen and (min-width: $breakpoint-lg) {
    .side-panel {
        display: grid;
    }
}
