// @import 'antd/dist/reset.css';


$primary-color: #7c5fbc;
$secondary-color: #8224e2;
$terciary-color: #5c349a;
$background-content: #fbf8f5;
$primary-text: rgba(0, 0, 0, 0.65);
$secondary-text: rgba(0, 0, 0, 0.45);
$danger-color: #cc0000;
$black: rgb(38, 38, 38);
$white: #fff;
$link: #45b1ff;

// AntD Breakpoints
// XS = < 576px
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

* {
    font-family: 'Titillium Web', sans-serif;
}

.ant-layout-header {
    background-color: $primary-color;

    .ant-menu-item a {
        color: $white;
    }
}

.label-primary {
    color: $primary-text;
    font-weight: 600;
}

.label-secondary {
    color: $secondary-text;
    font-weight: 600;
}

.rounded {
    border-radius: 5px;
}

@media screen and (min-width: $breakpoint-lg) {
    .only-mobile {
        display: none;
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .only-desktop {
        display: none;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .block-mobile {
        width: 100%;
    }
}

.full-width {
    width: 100%;
}

.radio-button-50 {
    width: 50%;
    text-align: center;
}

.radio-button-33 {
    width: 33%;
    text-align: center;
}

.radio-button-25 {
    width: 25%;
    text-align: center;
}

.button {
    @extend .rounded;
    border: 2px solid transparent;
    background-color: $primary-color;
    color: $white;
    transition: 0.5s;
    text-transform: uppercase;
    font-weight: 600;
}

.btn-cancel {
    background-color: transparent;
    border: 0;
    color: $black;
    font-weight: bold;
    box-shadow: transparent;
}

.button:hover {
    border: 2px solid $primary-color;
    background-color: transparent;
    color: $primary-color;
}
.primary:hover {
    border: 2px solid lighten($primary-color, 5%);
    background-color: lighten($primary-color, 5%);
    color: $white;
}

.btn-white {
    background-color: $white;
    border: 0;
    color: $black;
    box-shadow: 0 0 5px rgba(46, 46, 46, 0.671);
}
.btn-white:hover {
    background-color: $primary-color;
    color: $white;
}

.delete {
    border-color: $danger-color;
    background-color: $danger-color;
    @extend .rounded;
    font-weight: 600;
}

.delete:hover {
    background-color: darken($danger-color, 10%);
    color: #fff;
    border-color: darken($danger-color, 10%);
}
.delete:focus {
    border-color: darken($danger-color, 7%);
    background-color: darken($danger-color, 7%);
}

.delete-secondary {
    border-color: $danger-color;
    background-color: transparent;
    color: $danger-color;
    font-weight: 600;
}
.delete-secondary:hover {
    background-color: $danger-color;
    border-color: $danger-color;
    color: $white;
}

.delete-secondary:focus {
    background-color: darken($danger-color, 10%);
    border-color: darken($danger-color, 10%);
    color: $white;
}

.secondary {
    border: 2px solid $primary-color;
    background-color: transparent;
    color: $primary-color;
}
.secondary:hover {
    border: 2px solid transparent;
    background-color: $primary-color;
    color: $white;
    transition: 0.5s;
}

.text-primary {
    color: $primary-color;
}

.ant-layout-sider-children {
    background-color: $white;
}
.ant-layout-sider-trigger {
    background-color: darken($primary-color, 20%);
}

.ant-modal-content {
    border-radius: 7px;
}
.ant-modal-header {
    border-radius: 8px 8px 0 0;
}

.ant-page-header {
    border-radius: 8px 8px 0 0;
}

h4.ant-typography{
    margin-top: 0;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1{
    z-index: 9!important;
}

.ant-table-placeholder {
    z-index: 9!important;
}