@import 'src/base';

.expandable-row-report-file-detail {
    width: 100%;
    
    
    .expandable-row-report-file-detail-col {
        width: 100%; 
        max-height: 300px; 
        overflow-y: scroll;
        padding-right:1rem;
    }
    .expandable-row-report-file-detail-col::-webkit-scrollbar {
        width: 6px;             
    }
    
    .expandable-row-report-file-detail-col::-webkit-scrollbar-track {
        background: #dddddd99;        /* color of the tracking area */
        border-radius: 20px;       /* roundness of the scroll thumb */
    }
    
    .expandable-row-report-file-detail-col::-webkit-scrollbar-thumb {
        background-color: #88888899;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
    }
}