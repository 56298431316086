@import 'src/base';

.filter-modal-access-reports {
    .ant-modal-header {
        padding-bottom: 10px;
    }
    .ant-modal-content{
        .ant-modal-footer{
            display: flex;
        }
    } 
}