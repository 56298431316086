@import 'src/base';

.create-site {
    width: 100%;
    .site-page-header {
        border-bottom: 1px solid rgb(235, 237, 240);
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: .5rem
    }
    .form {
        width: 100%;
        padding: 1rem 1rem 0 1rem;
    }
}
