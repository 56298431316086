@import 'src/base';

.report-files-list {
    width: 90%;
    margin: auto;
    // border-radius: 5px;
    display: grid;
    // background-color: #fff;
    padding: 1rem;
}

@media screen and (max-width: $breakpoint-lg) {
    .report-files-list {
        width: 100%;
        margin: 0;
        padding: 1rem;
    }
}
